<template>
	<div class="wrapper">
		<admin-title :title="$route.meta.name"></admin-title>
		<div class="header-search">
			<el-form ref="form" inline :model="searchData" label-width="80px">
				<el-form-item label="门店名称">
					<el-input
						placeholder="请输入"
						v-model="searchData.storeName"
						clearable
						size="small"
					>
					</el-input>
				</el-form-item>
				<el-form-item label="订单号">
					<el-input
						placeholder="请输入"
						v-model="searchData.orderNo"
						clearable
						class="input-with-select"
						size="small"
					>
					</el-input>
				</el-form-item>
				<el-form-item label="结算时间">
					<el-date-picker
            style="width: 300px"
						v-model="searchData.settleDate"
						size="small"
						type="daterange"
						value-format="yyyy-MM-dd"
						range-separator="至"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
					>
					</el-date-picker>
				</el-form-item>
				<el-form-item label="结算状态">
					<el-select
						v-model="searchData.settleStatus"
						placeholder="请选择"
						size="small"
					>
						<el-option
							v-for="item in statusList"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="结算方式">
					<el-select
						v-model="searchData.settleType"
						placeholder="请选择"
						size="small"
					>
						<el-option
							v-for="item in payMethodsList"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label=" ">
					<el-button
						type="primary"
						size="small"
						icon="el-icon-search"
						@click="search"
						>搜索</el-button
					>
					<el-button
						type="info"
						size="small"
						icon="el-icon-refresh"
						@click="reset"
						>重置</el-button
					>
				</el-form-item>
			</el-form>
		</div>
		<el-divider></el-divider>
		<div class="header-btn">
			<el-button
				v-checkbtn="isShowBtn(AUTH_BTN.finance_store_settlement_export)"
				style="margin-bottom: 20px"
				type="primary"
				icon="el-icon-download"
				size="small"
				@click="downloadExcel"
				>导出</el-button
			>
		</div>

		<tp-table
			:isNeedSerialNumber="true"
			:tableData="list"
			:columns="columns"
			:totalNum="total"
			:current-page.sync="currentPage"
			:pageSize.sync="pageSize"
		/>
		<SettleDialog
			:orderNo="row.orderNo"
			:visible.sync="row.visible"
			:settleType="row.settleType"
			@ok="ok"
		/>
	</div>
</template>

<script>
import dayjs from "dayjs";
import { authBtnMixin } from "@/mixins/authBtnMixin";
import {
	getFinanceOrderListAPI,
	exportAPI,
	downloadReceiptAPI,
  downloadReceiptPdf,
	alipayRetry,
} from "./api";
import SettleDialog from "./settleDialog.vue";
import {dateFormat} from "@/utils";

const columns = [
	{
		label: "门店名称",
		prop: "storeName",
		minWidth: "220",
		customRender(h, row) {
			return <p>{row["storeName"]}</p>;
		},
	},
	{
		label: "订单号",
		prop: "orderNo",
		minWidth: "220",
		customRender(h, row) {
			return (
				<p>
					{row["orderNo"]}
					<el-tag
						v-show={row["enableAlipayFundAuth"]}
						size="mini"
						effect="dark"
					>
						免押
					</el-tag>
				</p>
			);
		},
	},
	{
		label: "商品信息",
		prop: "goodsTitle",
		minWidth: "180",
		customRender(h, row) {
			return <p class="goods-info">{row["goodsTitle"]}</p>;
		},
	},
  {
    label: "结算方式",
    prop: "settleTypeName",
    minWidth: "130",
    customRender(h, row) {
      return <p>{row.settleTypeName}</p>;
    },
  },
	{
		label: "收款账户信息",
		prop: "payeeAlipayAccount",
		minWidth: "220",
		customRender(h, row) {
			if (row.settleType === 1) {
				return <div>-</div>;
			}

			return (
				<div>
					<p>姓名：{row["payeeIdName"]}</p>
					<p>支付宝账号：{row["payeeAlipayAccount"]}</p>
				</div>
			);
		},
	},
	{
		label: "结算金额",
		minWidth: "100",
		prop: "settleAmount",
		customRender(h, row) {
			return <p>￥{row["settleAmount"]}</p>;
		},
	},
  {
    label: "结算时间",
    prop: "settleTime",
    minWidth: "160",
    customRender(h, row) {
      return <p>{row.settleTime}</p>;
    },
  },
  {
    label: "结算状态",
    prop: "settleStatusName",
    minWidth: "120",
    fixed: "right",
    customRender(h, row) {
      return (
          <p>
            <span v-show={row.settleStatus == 0}>{row["settleStatusName"]}</span>
            <span v-show={row.settleStatus == 1} style="color:green">{row["settleStatusName"]}</span>
            <div v-show={row.settleStatus == 2} style="color:red">
              <span style="margin-right: 5px;">{row["settleStatusName"]}</span>
              <el-tooltip effect="dark" placement="top-start">
                <template slot="content">
                  <p style="width: 200px;">{row["settleRemark"] || '暂无信息'}</p>
                </template>
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </div>
          </p>
      );
    },
  },
	{
		label: "操作",
		minWidth: "150",
		fixed: "right",
		align: "center",
		customRender(h, row) {
			return (
				<div>
          <el-link
              style="margin-right:10px"
              v-show={
                  this.showOfflinePayBtn(row) &&
                  this.isShowBtn(
                      this.AUTH_BTN.finance_store_settlement_offline_payment
                  )
              }
              underline={false}
              type="primary"
              onClick={() => this.showSettleDialog(row, 1)}
          >
            线下打款
          </el-link>
					<el-link
						style="margin-right:10px"
						v-show={
							this.onlinePayFail(row) &&
							this.isShowBtn(this.AUTH_BTN.finance_store_settlement_retry)
						}
						underline={false}
						type="primary"
						onClick={() => this.showSettleDialog(row, 2)}
					>
						重试
					</el-link>
          <el-link
              style="margin:0 10px;"
              underline={false}
              type="primary"
              onClick={() => this.downloadReceipt(row)}
              v-show={
                  this.onlinePaySuccess(row) &&
                  this.isShowBtn(this.AUTH_BTN.finance_store_settlement_receipt_download)
              }
          >
            下载回单
          </el-link>
				</div>
			);
		},
	},
];
export default {
	name: "StoreSettlementList",
	mixins: [authBtnMixin],
	components: {
		SettleDialog,
	},
	data() {
		return {
			statusList: [
        {
          label: "待打款",
          value: 0,
        },
				{
					label: "打款成功",
					value: 1,
				},
				{
					label: "打款失败",
					value: 2,
				},
			],
			payMethodsList: [
				{
					label: "线下打款",
					value: 1,
				},
				{
					label: "支付宝自动结算",
					value: 2,
				},
			],
			columns,
			currentPage: 1,
			pageSize: 10,
			total: 0,
			list: [],
			searchData: {
				storeName: "", //门店名称
				orderNo: "", //订单号
				settleDate: "", //结算日期
				settleStatus: "", //结算状态
				settleType: "", //结算方式
			},
			row: {},
		};
	},
	mounted() {
		this.getList();
	},
	watch: {
		currentPage() {
			this.getList();
		},
		pageSize() {
			this.getList();
		},
	},
	methods: {
		ok() {
			this.row.visible = false;
			this.getList();
		},
		onlinePaySuccess(row) {
			return (
				row.settleType === 2 && row.settleStatus == 1 && !this.ifOverTime(row)
			);
		},
		//重试按钮展示条件
		onlinePayFail(row) {
			//线上结算：settleType=2  结算状态：settleStatus：1成功
			return row.settleType === 2 && row.settleStatus == 2; //支付失败
		},
		showOfflinePayBtn(row) {
			// 线下结算 且 订单状态大于租赁中 且 结算状态 (待打款/打款失败)   或者 线上支付 结算状态为失败
			return (row.settleType == 1 && row.orderStatus >= 6 && row.settleStatus != 1) || this.onlinePayFail(row);
		},
		ifOverTime(row) {
			if (!row.settleTime) {
				return false;
			}

			const duration = dayjs().diff(dayjs(row.settleTime), "day");

			return duration > 365;
		},
		showSettleDialog(row, settleType) {
			this.row = {
				orderNo: row.orderNo,
				visible: true,
				settleType,
			};
		},
		search() {
			this.getList();
		},
		async getList() {
			let params = {
				page: this.currentPage,
				pageSize: this.pageSize,
				...this.searchData,
			};
      if (this.searchData.settleDate && this.searchData.settleDate.length > 0) {
        params.leftSettleDate = this.searchData.settleDate[0];
        params.rightSettleDate = this.searchData.settleDate[1];
        params.settleDate = '';
      }
			const res = await getFinanceOrderListAPI(params);
			this.list = res.list || [];
			this.total = res.total || 0;
		},
		downloadExcel() {
      let params = { ...this.searchData };
      if (this.searchData.settleDate && this.searchData.settleDate.length > 0) {
        params.leftSettleDate = this.searchData.settleDate[0];
        params.rightSettleDate = this.searchData.settleDate[1];
        params.settleDate = '';
      }
			exportAPI(params);
		},
		reset() {
			Object.assign(this._data.searchData, this.$options.data().searchData);
			this.getList();
		},
		async downloadReceipt(row) {
			const res = await downloadReceiptAPI({ orderNo: row.orderNo });
      downloadReceiptPdf(res.receiptFileUrl, `结算回单${row.orderNo}-${dateFormat()}.pdf`)
		},
	},
};
</script>

<style lang="scss" scoped>
.mr20 {
	margin-right: 20px;
}
.wrapper {
	width: 100%;
	.header-search {
		.el-form {
			.el-form-item {
				margin-right: 35px;
				.el-input {
					width: 200px;
				}
				.el-select {
					width: 200px;
				}
				.el-date-editor {
					width: 200px;
					/deep/.el-range-separator {
						width: auto;
					}
				}
			}
		}
	}
	.el-divider--horizontal {
		margin: 0 0 20px;
	}
	.header-btn {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
	.tp-table {
		.el-table__fixed-right {
			height: 100% !important;
		}
	}
	.el-dialog__body {
		padding-top: 0;
	}
	.goods-info {
		display: flex;
	}
}
</style>
