import { http } from '@/http/axios.js';
import axios from 'axios';
import { getToken } from '@/utils/auth';
import { showScreenLoading, hideScreenLoading } from '@/http/loading.js';
import { Message } from 'element-ui';
import { dateFormat } from '@/utils/index.js';
// 列表
export function getFinanceOrderListAPI(params) {
    return http({
        url: '/admin/store/order/settle/page',
        method: 'get',
        params,
    });
}

// 门店待结算金额详情
export function getSettleInfo(params) {
    return http({
        url: '/admin/order/store/settle/info',
        method: 'get',
        params,
    });
}
//重试
export function alipayRetry(data) {
    return http({
        url: '/admin/store/order/settle/alipay/transfer',
        method: 'post',
        data,
    });
}
// 线下打款
export function offlinePay(data) {
    return http({
        url: '/admin/store/order/settle/offline/transfer',
        method: 'post',
        data,
    });
}
// 导出
export function downloadReceiptAPI(data) {
    return http({
        url: '/admin/store/order/settle/receipt/download',
        method: 'post',
        data,
    });
}
export function downloadReceiptPdf(pdfUrl, filename) {
    showScreenLoading();
    axios({
        url: pdfUrl,
        method: 'get',
        responseType: 'blob',
    }).then(
        (res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            hideScreenLoading();
        },
        (reason) => {
            hideScreenLoading();
            Message.error('下载失败！');
        }
    );
}
export function exportAPI(data) {
    showScreenLoading();
    axios({
        method: 'post',
        url: '/api/admin/store/order/settle/export',
        responseType: 'blob',
        data,
        headers: {
            Authorization: getToken() || '',
        },
    })
        .then(async (res) => {
            hideScreenLoading();
            let data = res.data;
            let url = window.URL.createObjectURL(new Blob([data]));
            let a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            let filename = `门店结算列表${dateFormat()}.xlsx`;
            a.setAttribute('download', decodeURIComponent(filename));
            document.body.appendChild(a);
            a.click(); //执行下载
            window.URL.revokeObjectURL(a.href); //释放url
            document.body.removeChild(a); //释放标签
        })
        .catch((error) => {
            hideScreenLoading();
        });
}
